import {useEffect,  Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import AOS from "aos";
import NavScrollTop from './components/NavScrollTop';
const HomeOne = lazy(() => import("./pages/HomeOne"));
const About = lazy(() => import("./pages/About"));
const Work = lazy(() => import("./pages/Work"));
const Contact = lazy(() => import("./pages/Contact"));
const Items = lazy(() => import("./pages/Items"));
const Services = lazy(() => import("./pages/OurServices"));
const ProductDetails = lazy(() => import("./pages/ProductDetails"));



function App() {
  useEffect(() => {
    AOS.init({
        offset: 80,
        duration: 1000,
        once: true,
        easing: 'ease',
    });
    AOS.refresh();
    
  }, [])
  return (
      <Router>
        <NavScrollTop>
          <Suspense fallback={<div />}>
                <Routes>
                  <Route path="*" element={<Navigate to="/" replace />} />
                  <Route path={`${process.env.PUBLIC_URL + "/"}`} element={<HomeOne/>}/>
                  <Route path={`${process.env.PUBLIC_URL + "/about"}`} element={<About/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/products"}`} element={<Work/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/services"}`} element={<Services/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/contact"}`} element={<Contact/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/products/:catName"}`} element={<Items/>}/>
                  <Route path={`${process.env.PUBLIC_URL + "/products/:catName/:catE/:subCat"}`} element={<Items/>}/>
                  <Route path={`${process.env.PUBLIC_URL + "/products/items/:slug"}`} element={<ProductDetails/>}/>
                </Routes>
            </Suspense>
        </NavScrollTop>
      </Router>
  );
}

export default App;
